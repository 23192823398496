import React, { useEffect } from "react";
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { useParams } from "react-router-dom";
import { useTranslation, withTranslation } from 'react-i18next';

import ProductSearch from "../../Components/Order/ProductSearch";
import CustomerPurchaseHistory from "../../Components/OrderPlacement/CustomerPurchaseHistory";
import VonProductContainer from "./VonProductContainer";
import VbProductContainer from "./VbProductContainer";
import BlikkProductContainer from "./BlikkProductContainer";
import orderActions from '../../Actions/orderActions';
import commonActions from '../../Actions/commonActions';
import system from '../../Constants/system';
import Grid from "../../Components/Grid/Grid";
import CheckoutContainer from "./CheckoutContainer";
import MessageAlertLevel from "../../Components/MessageAlertLevel";
import { hasReadWriteAccess } from "../../Utils/permissionUtils";
import { UserRoles } from "../../Constants/userRoles";
import VossProductContainer from "./VossProductContainer";
import { ProductConfirmation } from "../../Components/OrderPlacement/ProductComponents/ProductConfirmation";
import { ProductWarnings } from "../../Components/OrderPlacement/ProductComponents/ProductWarnings";

const OrderPlacementContainer = ({ ...props }) => {

  const { orderActions, searchResults, orderId, order, isVonEnabled, customer, pushedQuotation, user, product, sourceSystem } = props;

  const { leadId } = useParams();
  const { t } = useTranslation();

  useEffect(() => {
    orderActions.startOrder(customer.customerNumber, leadId, pushedQuotation);

    return () => {
      orderActions.clearOrder();
      orderActions.clearPushedQuotation();
    };
  }, []);

  const onSearch = (query) => {
    orderActions.search(order.customerNumber, query, isVonEnabled);
  };

  const onProductClick = (productId, sourceSystem) => {
    switch (sourceSystem) {
      case system.Von:
        orderActions.getVonProductDetails(productId, customer.customerNumber, 0, orderId);
        break;
      case system.Vb:
        orderActions.getProductDetails(productId, customer.customerNumber, 0, null, null, orderId);
        break;
      case system.CompanyExpense:
        orderActions.getCompanyExpenseProductDetails(productId, customer.customerNumber, -1, orderId);
        break;
      case system.Blikk:
        orderActions.getBlikkProductDetails(productId, customer.customerNumber, orderId);
        break;
      case system.Voss:
        orderActions.getVossProductDetails(productId, customer.customerNumber, orderId);
        break;
      default:
        break;
    }
  };

  const parseValidationRules = (orderLimitationReason) => {

    if (customer.isNonInteractive)
      return "ordercontainer.noninteractive"

    switch (orderLimitationReason) {
      case 'BadGroupType':
        return 'ordercontainer.badgrouptype'
      case 'BadStatus':
        return 'ordercontainer.badstatus'
      case 'AOProduct':
        if (user && hasReadWriteAccess(user, UserRoles.AOSales))
          break;
        return 'ordercontainer.aoproduct'
      case 'AOCentralAgreement':
        if (user && hasReadWriteAccess(user, UserRoles.AOSales))
          break;
        return 'ordercontainer.aocentralagreement'
      case 'NoMainCustomer':
        return 'ordercontainer.nomaincustomer'
      default:
        return null;
    }
  }

  const message = parseValidationRules(customer.orderLimitationReason);
  if (message) return <MessageAlertLevel message={t(message)} />;

  const renderProductDetails = () => {

    let productDescription;

    if (product) {
      switch (sourceSystem) {
        case system.Voss:
          let tier = product.offer.tiers.find(tier => tier.id === product.priceCalculation.tierId);
          productDescription = tier ? `${product.productDescription} - ${tier.name}` : product.productDescription;
          break;
        default:
          productDescription = product.productDescription;
      }
    }

    return <h2 className="font-family-ubuntu-semibold mb-0">{productDescription}</h2>
  };

  return <Grid>
    <div className="mt-24">
      <ProductSearch title={t('ordercontainer.productsearch')} results={searchResults} onSearch={onSearch} onProductClick={onProductClick} isVonEnabled={isVonEnabled} />
    </div>
    <div>
      <div className="mb-24">
        {renderProductDetails()}
      </div>
      <div className="mb-64">
        <Grid>
          <Grid.Row>
            <Grid.Row.Column>
              {product && order && (
                <>
                  <ProductConfirmation {...props} />
                  <ProductWarnings {...props} />
                  {sourceSystem === system.Von && <VonProductContainer />}
                  {sourceSystem === system.Vb && <VbProductContainer />}
                  {sourceSystem === system.Blikk && <BlikkProductContainer />}
                  {sourceSystem === system.Voss && <VossProductContainer />}
                </>
              )}
            </Grid.Row.Column>
            <Grid.Row.Column width={3}>
              <CustomerPurchaseHistory {...props} />
            </Grid.Row.Column>
          </Grid.Row>
        </Grid>
      </div>
      {order && order.orderlines.length > 0 && <CheckoutContainer />}
    </div>
  </Grid>
};

const mapStateToProps = (state) => ({
  searchResults: state.OrderReducer.searchResults,
  orderId: state.OrderReducer.orderId,
  order: state.OrderReducer.order,
  isVonEnabled: state.OrderReducer.isVonEnabled,
  customer: state.CustomerReducer.selectedCustomer,
  orderForm: state.CommonReducer.forms && state.CommonReducer.forms.orderForm,
  pushedQuotation: state.OrderReducer.pushedQuotation,
  user: state.CommonReducer.user,
  product: state.OrderReducer.product && state.OrderReducer.product,
  sourceSystem: state.OrderReducer.sourceSystem && state.OrderReducer.sourceSystem,
  editOrderline: state.OrderReducer.editOrderline,
});

const mapDispatchToProps = (dispatch) => ({
  orderActions: bindActionCreators(orderActions, dispatch),
  commonActions: bindActionCreators(commonActions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(OrderPlacementContainer);