import React, { useEffect } from 'react';
import { connect } from 'react-redux';

import * as calcTypes from '../../Constants/priceCalculationTypes';
import Grid from "../../Components/Grid/Grid";
import orderActions from '../../Actions/orderActions';
import { bindActionCreators } from 'redux';
import { VossDiscountOptionsCard } from '../../Components/OrderPlacement/ProductComponents/Voss/VossDiscountOptionsCard';
import { VossTierOptionsCard } from '../../Components/OrderPlacement/ProductComponents/Voss/VossTierOptionsCard';
import { VossTierSelector } from '../../Components/OrderPlacement/ProductComponents/Voss/VossTierSelector';
import { VossProductSummaryCard } from '../../Components/OrderPlacement/ProductComponents/Voss/VossProductSummaryCard';
import { hasAccess } from '../../Utils/permissionUtils';
import { Access, UserRoles } from '../../Constants/userRoles';
import { useTranslation } from 'react-i18next';

function VossProductContainer({ ...props }) {
  const { orderActions, product } = props;
  const { t } = useTranslation();

  useEffect(() => {
    hasAccess(props.user, UserRoles.PartnerSales, Access.ReadWrite) &&
      orderActions.getVossIntermediaries();
  }, []);

  const onChange = (key, value) => {
    var prevPriceCalculation = { ...product.priceCalculation };
    product.priceCalculation[key] = value;
    orderActions.getVossPriceCalculations(product.offer, product.priceCalculation, prevPriceCalculation);
  };

  return <>
    {product.priceCalculation.tierId ? (
      <>
        {product.offer.tiers.length > 1 && <div><button className='btn btn-primary mb-0' onClick={() => orderActions.getVossPriceCalculations(product.offer)}>{t('vossproductcontainer.changetier')}</button></div>}
        <Grid.Row>
          <Grid.Row.Column width={4}>
            <VossTierOptionsCard onChange={onChange} {...props} />
          </Grid.Row.Column>
          {product.offer.discounts.length > 0 && <Grid.Row.Column>
            <VossDiscountOptionsCard onChange={onChange} {...props} />
          </Grid.Row.Column>}
        </Grid.Row>
        <Grid.Row>
          <Grid.Row.Column>
            <VossProductSummaryCard {...props} priceCalcType={calcTypes.priceCalculationTypes.order} />
          </Grid.Row.Column>
        </Grid.Row>
      </>
    ) : (
      <VossTierSelector {...props} />
    )}
  </>
};

const mapDispatchToProps = (dispatch) => ({
  orderActions: bindActionCreators(orderActions, dispatch),
});

const mapStateToProps = (state) => ({
  order: state.OrderReducer.order,
  intermediaries: state.OrderReducer.vossIntermediaries,
  customer: state.CustomerReducer.selectedCustomer,
  user: state.CommonReducer.user,
  product: state.OrderReducer.product && state.OrderReducer.product,
  editOrderline: state.OrderReducer.editOrderline
});

export default connect(mapStateToProps, mapDispatchToProps)(VossProductContainer);